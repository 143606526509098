'use strict';

function setActiveLink() {
    var scrollPos = $(window).scrollTop();
    $('.row-links .tile-wrap').each(function () {
        var currLink = $(this);
        var anchorName = currLink.find('a.anchor-link').attr('href').substring(1); // Remove the '#' from href
        var refElement = $('a[name="' + anchorName + '"]');
        if (refElement.offset().top - 200 <= scrollPos && refElement.offset().top + refElement.closest('.experience-component').height() - 200 > scrollPos) {
            if (!currLink.hasClass('active')) {
                $('.row-links .tile-wrap')
                    .removeClass('active');
                currLink.addClass('active');
                var activeMenu = $('.row-links .tile-wrap.active');
                if (activeMenu.length) {
                    var toScroll = (activeMenu.position().left - $(window).width() / 2 + activeMenu.width() / 2) + $('.row-links').scrollLeft();
                    console.log(toScroll);
                    if ($('.row-links').scrollLeft() != toScroll) {
                        $('.row-links')
                            .scrollLeft(toScroll);
                    }
                }
            }
        } else {
            currLink.removeClass('active');
        }
    });
}

function updateStickyMenuTop() {
    var mainNavHeight = $('.sticky-header header').outerHeight(); // Get the outer height with jQuery
    $('.sticky-header .cat-landing .experience-commerce_layouts-navRow').css('top', mainNavHeight + 'px'); // Set the top style with jQuery
}

$(document).ready(function () {
    setTimeout(function () {
        updateStickyMenuTop();
    }, 1000);

    const activeElement = document.querySelector('.row-links .tile-wrap.active');
    if (activeElement) {
        activeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }

    $(window).on('scroll', setActiveLink);
    $(window).on('resize', updateStickyMenuTop);

    // Initialize the submenu position to the active item on load
    var activeMenu = $('.row-links .tile-wrap.active');
    if (activeMenu.length) {
        $('.row-links').scrollLeft(activeMenu.position().left - $(window).width() / 2 + activeMenu.width() / 2);
    }
});
